@media print {

  /* If a rule is marked !important, it overrules an inline rule */

  /* Margin has to be 0 to remove page title and print date */
  @page {
    margin: 2cm auto 2cm;
  }

  html, body {
    display: block;
    font-size: 11px;
  }

  body div {
    max-width: 100%;
  }

  header.MuiPaper-root {
    margin-bottom: 20pt;
  }

  form > div {
    padding: 0 !important;
  }

  .makeStyles-icmApplicationHeader-1> * {
    margin: 0 !important;
  }

  .printNoBreak::after, .printNoBreak *::after {
    content: "";
    display: block;
    height: 100px;
    margin-bottom: -100px;
}

  .MuiBox-root, .MuiCollapse-wrapper {
    padding: unset;
  }

  .MuiGrid-root.MuiGrid-container {
    margin-left: unset;
    margin-top: unset;
  }

  .MuiGrid-root.MuiGrid-item > #readonly-container {
    break-inside: avoid;
  }

  .MuiPaper-root, .MuiCollapse-wrapper, .MuiGrid-root, .MuiBox-root:not([hidden]) {
    display: block;
  }

  .MuiGrid-root > .MuiGrid-item {
    display: block;
    margin-top: revert;
    min-height: unset !important;
    padding-left: unset;
    padding-top: unset;
  }

  .MuiGrid-root .MuiPaper-root {
    display: block;
  }

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiGrid-root > .MuiGrid-item::after {
    display: block;
    height: 100px;
    margin-bottom: -100px;
  }

  /* Controls the header of the accordion. */
  .MuiButtonBase-root {
    background-color: transparent !important;
    border: thin solid;
    display: block;
    visibility: visible;
  }

  .MuiAccordionSummary-content, .MuiAccordionSummary-root, .Mui-expanded {
    break-inside: avoid;
    display: block;
    flex-grow: unset;
    min-height: unset !important;
  }

  .MuiAccordionSummary-content {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .MuiAccordionSummary-expandIconWrapper {
    display: none;
  }

  .dontPrint {
    display: none !important;
  }

  .print {
    height: auto !important;
    overflow: visible !important;
    width: auto !important;
  }

  .printNoBorders {
    border: none !important;
  }

  .printNoBoxShadow {
    box-shadow: none !important;
  }

  .printTwoColumns > *  {
    display: inline-block;
    vertical-align: text-top;
    width: 50%;
  }

  * {
    overflow: visible !important;
  }

  .label-with-state-marker > div {
    display: none;
  }

  .MuiTooltip-popper {
    display: none !important;
  }

  .MuiFab-root {
    display: none !important;
  }

  .MuiCollapse-wrapper .SM > .MuiGrid-item:not(.MuiGrid-grid-lg-12):not(.MuiGrid-grid-lg-6):not([style="width: 100%;"]) {
    display: inline-block;
    margin-right: 1%;
    vertical-align: text-top;
    width: 24%;
  }

  /* Expands Accordion elements for printing. Page breaks within these elements are discouraged. */
  .MuiCollapse-root {
    height: auto !important;
    visibility: visible;
  }

  .MuiSvgIcon-root {
    vertical-align: bottom;
  }

  /* Hide notification messages */
  .SnackbarContainer-root {
    display: none !important;
  }

  /* Hide change indicator */
  .MuiTypography-root.MuiTypography-caption {
    display: none;
  }

  /* Hides the down/up arrow of Accordion elements for mouse expansion/collapsing. */
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    visibility: hidden;
  }

  /* Configures the font-size and the distance of dynamic text elements to static text elements. */
  .MuiTypography-root.MuiTypography-body1 {
    margin-bottom: revert;
  }

  .MuiTypography-root.MuiTypography-body2:not(.value) {
    color: unset;
    font-size: unset;
    font-weight: bold;
    line-height: unset;
  }

  .MuiTypography-root.MuiTypography-body2 > span {
    display: inline !important;
  }

  .MuiTypography-root.MuiTypography-body2 > span::after {
    content: ":";
  }

  div[data-testid="split-view-view"] {
    width: 100% !important;
  }

  div[role="table"] {
    min-width: unset !important;
  }

  .MuiTable-root {
    page-break-inside: auto;
    table-layout: fixed;
  }

  .MuiTable-root > div[role] {
    border-bottom: unset !important;
    font-weight: bold !important;
  }

  .MuiTableRow-root {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .MuiTableRow-root > div.MuiTableCell-root {
    border: thin solid !important;
    display: inline-block !important;
    font-weight: unset !important;
    padding: 3px !important;
  }

  .MuiTableRow-root > .MuiTableCell-root:first-child:empty {
    display: none !important;
  }

  .MuiTableRow-root > .MuiTableCell-root:not(:last-of-type) {
    border-right: unset !important;
  }

  div[data-test-id="virtuoso-item-list"] > div .MuiTableCell-root {
    border-top: unset !important;
  }


  .MuiTableRow-root > .MuiTableCell-root:first-of-type:empty {
    display: none;
  }

  .MuiTableRow-root > .MuiTableCell-root > span.MuiButtonBase-root {
    border: unset !important;
    display: inline-block;
    width: calc(100%) !important;
  }

  .MuiTableCell-root .MuiTypography-root {
    white-space: pre-wrap !important;
  }

  .MuiAccordion-region .MuiPaper-root div {
    height: unset;
  }

  table .MuiTableCell-root {
    white-space: pre-wrap;
    word-break: break-word;
  }

  .MuiTableHead-root {
    break-inside: auto;
    white-space: pre-wrap;
    word-break: break-word;
  }

}
